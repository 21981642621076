
















import Vue from "vue";
export default Vue.extend({
  name: "SocialReference",
  components: {},
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    link: {
      type: String,
      required: false,
      default: null,
    },
    graphic: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    open() {
      if (this.link) window.open(this.link, "_blank");
    },
  },
});
