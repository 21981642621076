









































import Vue from "vue";
import AppBar from "../components/AppBar.vue";
import Header from "../components/Header.vue";
import SocialReference from "../components/SocialReference.vue";
import RepoMessage from "../components/RepoMessage.vue";
import Footer from "../components/Footer.vue";
import {
  mdiCoffee,
  mdiCubeOutline,
  mdiGithub,
  mdiInstagram,
  mdiLinkedin,
  mdiMusicNoteOutline,
  mdiNpm,
  mdiSnapchat,
  mdiStackOverflow,
  mdiTwitter,
} from "@mdi/js";
import SnapcodeBitmoji from "../assets/snapcodeBitmoji.svg";

export default Vue.extend({
  name: "Home",
  components: {
    AppBar,
    Header,
    SocialReference,
    RepoMessage,
    Footer,
  },
  data() {
    return {
      tabs: null,
      gridProps: {
        cols: 12,
        xs: 3,
        sm: 3,
        md: 6,
        lg: 6,
      },
      references: [
        {
          icon: mdiGithub,
          label: "GitHub",
          link: "https://github.com/NoodleOfDeath",
        },
        {
          icon: mdiCubeOutline,
          label: "HackTheBox",
          link: "https://app.hackthebox.com/profile/639343",
        },
        {
          icon: mdiInstagram,
          label: "Instagram",
          link: "https://instagram.com/rolling_codes",
        },
        {
          icon: mdiLinkedin,
          label: "LinkedIn",
          link: "https://linkedin.com/in/thommorgan",
        },
        {
          icon: mdiNpm,
          label: "NPM",
          link: "https://www.npmjs.com/~noodleofdeath",
        },
        {
          icon: mdiSnapchat,
          label: "Snapchat",
          graphic: SnapcodeBitmoji,
        },
        {
          icon: mdiStackOverflow,
          label: "StackOverflow",
          link: "https://stackoverflow.com/users/409958/rolling_codes",
        },
        {
          icon: mdiMusicNoteOutline,
          label: "TikTok",
          link: "https://tiktok.com/@rolling_codes",
        },
        {
          icon: mdiTwitter,
          label: "Twitter",
          link: "https://twitter.com/nftofdeath",
        },
      ],
      mdiCoffee,
    };
  },
  methods: {
    buyCoffee() {
      window.open("https://buymeacoffee.com/NoodleOfDeath", "_blank");
    },
  },
});
