















import Vue from "vue";

/**
 * TODO: Make this component actually fetch the code from a
 * particular repo and let you "carousel" through the code
 */
export default Vue.extend({
  name: "RepoMessage",
  props: {
    user: {
      type: String,
      required: true,
    },
    repo: {
      type: String,
      required: true,
    },
  },
});
